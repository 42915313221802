<template>
  <div class="">
    <div class="w-full flex text-c16 font-c4 text-left items-center item cursor-pointer">
      <div class="w-1/4 flex items-center font-c6">
        <icon
          v-if="!item.sfd.pictureId"
          :data="icons.entreprise"
          height="20"
          width="20"
          class="mr-2 cursor-pointer"
          original
        />
        <img
          :src="item.sfd.pictureId"
          alt=""
          class="h-10 w-10 rounded-100 mr-3"
          v-if="item.sfd.pictureId"
        >

        <div>{{ item.sfd.name }}</div>
      </div>

      <div class="w-1/6 ml-4 flex items-center">
        <span v-if="item.sfd.phoneNumber">{{ item.sfd.phoneNumber }}</span>
        <span v-if="!item.sfd.phoneNumber">-</span>
      </div>

      <div class="w-1/5 ml-4">
        <span v-if="item.sfd.email"> {{ item.sfd.email }} </span>
        <span v-if="!item.sfd.email">-</span>
      </div>

      <div class="w-1/4 ml-4 flex items-center flex items-center">
        <icon
          :data="icons.user"
          height="20"
          width="20"
          class="mr-2 cursor-pointer"
          original
        />
        <div v-if="item.admin">
          {{ item.admin.lastName }} {{ item.admin.firstName }}
        </div>
      </div>

      <div class="w-1/6 ml-4 text-center">
        {{ item.totalCommerce }}
      </div>
    </div>
  </div>
</template>

<script>
import user from '../../../assets/icons/ic-clientAv.svg'
import entreprise from '../../../assets/icons/ic-entreprise.svg'

export default {
  name: "Index",
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      icons: {
        user,
        entreprise
      },
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon{
  fill: transparent;
}
.item:hover{
  border-left: 3px groove black;
  padding-left: 30px;
}
</style>
